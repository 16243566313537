<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcPlacementItems }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>
              {{ translations.tcPlacementItems }}
              <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcPlacementItems">
                <i-tooltip />
              </span>
            </h2>
            <ToggleArrow v-if="toggle == true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
            </ToggleArrow>
          </header>
          <b-button class="mb-5" v-if="toggleMe && iCanSeeAny(secured_placement_items_edit_controls)" variant="primary"
            @click="handledEditItems()">{{ translations.tcEditPlacementItems }}</b-button>
          <div v-if="toggleMe" class="">
            <div class="presentation-specs" v-for="(itm, index) in placementItemsTranslated" :key="index">
              <b-row style="margin-bottom: 0px">
                <b-col sm="12" class style="margin-bottom: 10px">
                  <div class="font-weight-bold" style="margin-bottom: 10px">
                    <span class="value">{{ itm.item_type_name }}</span>
                  </div>
                </b-col>
                <b-col sm="4" class>
                  <div class="font-weight-bold mr-6">
                    {{ itm.pfd_count }}
                  </div>
                </b-col>
              </b-row>
            </div>
            <p v-if="placementItemsTranslated.length === 0">{{ translations.tcNoPlacementItemsFound }}</p>
          </div>
        </div>
      </section>
    </page-body>

    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpLocation.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'placement-items',
  mixins: [translationMixin],
  data() {
    return {
      backJumpObj: {
        link: '#',
        location: '',
      },
      placementItemsTranslated: [],
      Section2: true,
      secured_placement_items_edit_controls: {
        edit_aux_facility_placement_items_button: '34eb9cb3-630d-4087-96c6-ea2b724546a4',
        edit_scripture_distribution_placement_items_button: '99a08208-e8e5-44d1-bb10-2bce5ff688f1',
      },
      toggle: true,
      toggleMe: true,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      loadPlacementItems: 'scriptureDistribution/loadPlacementItems',
    }),
    handledEditItems() {
      this.$router.push('/programs/sd/edit-placement-items')
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: this.userSelectedOrgTypeKey }),
        this.loadPlacementItems(this.userSelectedLocationKey),
        (this.backJumpObj.location = this.locationDetail.org_name),
        (this.backJumpObj.link = '/programs/sd/location-profile'),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    translatePlacementItems() {
      if (!this.translations.common || !this.placementItems) return false

      return this.placementItemsTranslated = this.placementItems.map(item => {
        const itemGuid = item.pit_key || ''
        const itemName = this.translations.common['placement-item-types'].hasOwnProperty(itemGuid) ?
          this.translations.common['placement-item-types'][itemGuid] :
          item.item_type_name

        return { ...item, item_type_name: itemName }
      })
    }
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump-location', 'quick-jump-locations'),
      this.getComponentTranslations('common.placement-item-types'),
      this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)
      this.translatePlacementItems()
    })
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      locationDetail: 'scriptureDistribution/locationDetail',
      placementItems: 'scriptureDistribution/placementItems',
      prefCulture: 'user/userPreferredCulture',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
    }),
  },
  components: {
    BackJump: BackJump,
    iTooltip: iTooltip,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.service-times-presentations {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px 20px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;

      h2 {
        flex: 1 1 auto;

        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }

      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.presentation-specs,
.presentation-details {
  max-width: 680px;
  columns: 2;
  column-gap: 40px;
  margin-bottom: 22px;
  // padding-bottom: 22px;
  border-bottom: 1px solid #979797;

  @include breakpoint(sm) {
    columns: 1;
    column-gap: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  div {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;

    span {
      display: block;
      color: #636363;
      font-weight: 700;
      line-height: 22px;
    }

    .label {
      display: block;
      margin-right: 0.25rem;
      text-transform: uppercase;
    }
  }
}
</style>
